body {
  margin: 0;
  font-family: inter;
  background-image: url("./assets/background_gradiant.4992cf895e6b2f4a07d7.png");
  background-size: cover;
  background-repeat: repeat;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@media screen and (max-width: 600px){
  body{
    font-family: Inter;
    background: #FAFAFD;
    background-image: unset;
  }}
