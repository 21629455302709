.search__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
}

.claim-search{
    height: auto !important;
    width: 100%;
}

.claim__search{
    background: #FFFFFF;
    width: 486px;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #E5E8F0;
    padding: 30px 0 24px 0;
}

.search__details{
    display: flex;
    flex-direction: column;
    padding: 30px;
}

.search__title{
    padding-left: 30px;
    padding-bottom: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 126%;
}
.claim__search__content{
    max-width: calc(100% - 20px);
    margin: 60px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-around;

}

.copy__search{
  margin-left: 10px;
}

.copy__search:hover {
  cursor: pointer;
}

.nft__pic{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.nft__pic img{
    width: 100%;
}

.search__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
}

.search{
    background: #FFFFFF;
    width: 486px;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #E5E8F0;
    padding: 30px 0 24px 0;
    margin-bottom: 10px;
}

.search__title{
    padding-left: 30px;
    padding-bottom: 22px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 126%;
}
.nft__content{
    max-width: calc(100% - 20px);
    margin: 0 auto;
    height: 100%;
    
    /* overflow: hidden; */
}

.nft__pic{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nft__pic img{
    width: 70%;
}

.staker{
    display: flex;
    height: 48px;
    padding: 13px;
    margin: 6px 30px 20px 30px;
    justify-content: space-between;
    background: #FBFCFF;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    /* width: 140px; */
    border-radius: 6px;
}

.gallery__btn{
    height: 44px;
    width: calc(100% - 20px) ;
    background-color: #3180F7;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.03em;
    color: #FFF !important;
    margin: 5px 0;
    transition: .17s;
    justify-self: flex-end;
}

.gallery__btn:hover{
    background-color: #2549D1;
    cursor: pointer;
}

.search__loader,
.search__loader:before,
.search__loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.search__loader {
  position: absolute;
  top: 300px;
  color: #3180F7;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.search__loader:before,
.search__loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.search__loader:before {
  left: -2em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.search__loader:after {
  left: 2em;
}

.not-exist{
  /* background-color: tomato; */
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-exist span{
 width: 200px;
 height: 100px;
 background-color: #fff;
 display: flex;
 justify-content: center;
 align-items: center;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


@media (max-width: 1107px){
    .search__container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
    }
    .gallery__container{
        justify-content: center;
    }
}

@media screen and (max-width: 600px){
  .claim-search{
    /* width: calc(100% - 20px) !important; */
    width: 100% !important;
  }
  .claim__search{
    /* width: calc(100% - 20px) !important; */
    width: 100% !important;

  }
  .staker{
    margin: unset;

    /* width: calc(100% - 20px) !important; */
  }
  .nft__pic{
    margin-bottom: 20px;
  }
  .staker{
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 375px){
  .claim-search{
    /* width: calc(100% - 20px) !important; */
    width: 100% !important;
  }
  .claim__search{
    /* width: calc(100% - 20px) !important; */
    width: 100% !important;
  }
}
@media screen and (max-width: 375px){
  .staker span{
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: calc(100% - 20px) !important; */
  }
}
