
.rewards{
    margin: 0 auto 20px auto;
    padding: 15px;
    max-width:  560px;
    height: auto;
    display: flex;
    background: #FBFCFF;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
}

.rewards__header{
    padding: 0 30px 0 30px;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 126%;
    letter-spacing: 0.03em;
}

.rewards__widget{
    flex-basis: 149px;
}

.rewards__widget img{
    width: 100%;
    border-radius: 4px;
}

.gallery-link{
    color: #2853EB !important;
}

.rewards__content{
    flex-basis: 355px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.rewards__subtitle{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 146%;

    padding-bottom: 12px;

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
}

.rewards__text{
    line-height: 160%;
    font-weight: 400;
    color: #686E7D;
}


@media screen and (max-width: 600px){
    .stake__rewards{
        padding: 10px;    
    }
    
    .rewards{
        flex-direction: column;
        height: auto;
        max-width: unset;
    }

    .rewards__content{
        flex-basis: auto;
        display: flex;
    }
    .rewards__header{
        padding: 5px 0 12px 0;
    }
    .rewards{
        margin: 0;
    }

    .rewards__subtitle{
        margin-top: 16px;
        font-size: 18px;
        padding-bottom: 10px;
    }
    .rewards__text{
        padding-top: 14px;
        font-size: 16px;
    }
  
}

@media screen and (max-width: 425px){
    .rewards__title{
        font-weight: 600;
        font-size: 14px;
        line-height: 126%;
        letter-spacing: 0.03em;
    }
    .rewards__subtitle{
        font-weight: 500;
        font-size: 16px;
        line-height: 146%;
        letter-spacing: 0.03em;
    }
    .rewards__text{
        font-size: 16px;
        line-height: 133%;
    }
}