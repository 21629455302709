.stake__info{
    max-width: 562px;
    height: auto;
    margin: 0 30px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background: #F4F7FF;
    border: 1px solid #2A61EE;
    box-sizing: border-box;
    border-radius: 6px;
}

.info__title {
    display: flex;
}

.info__title span{
    padding-left: 14px;
    font-size: 16px;
}

.info__text{
    padding-top: 5px;
    padding-left: 35px;
    line-height: 126%;
    color: #686E7D;;
    font-size: 14px;
}


@media screen and (max-width: 600px){
    .info__title {
        margin-bottom: 10px;
        font-size: 18px; 
    }
    .stake__info{
        max-width: 600px;
        margin: 10px;
        padding: 20px 14px;
        height: auto;
    }
    .info__text{
        font-size: 16px;
        line-height: 126%;
        opacity: 0.4;
    }
}

@media screen and (max-width: 425px){
    .info__title{
        font-size: 16px;
        line-height: 126%;
        letter-spacing: 0.03em;
    }
    .info__text{
        font-size: 16px;
        line-height: 126%;
        letter-spacing: 0.03em;
        color: #686E7D;
        opacity: unset;
    }
}