.duration{
    flex-basis: 125px;
    height: 80px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: rgba(229, 232, 240, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    transition: .17s;
    border: 1px solid transparent; 
}

.duration:hover{
    background: rgb(229, 232, 240, 1);
}


.duration--active{
    padding: 16px;
    background: #FFFFFF !important; 
    border-color:  #3180F7;
    pointer-events: none;
}

.duration__title{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 126%;
    letter-spacing: 0.03em;
}

.duration__subtitle{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 126%;
    letter-spacing: 0.03em;
    color: #1D1D1D;
}

@media screen and (max-width: 600px){
    .duration{
        flex-basis: 48%;

    }
}


@media screen and (max-width: 425px) {
    .duration{
        /* flex-basis: 48%; */
        margin-bottom: 5px;
    }
    .duration__title{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 126%;
    }
    .duration__subtitle{
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 126%;
        letter-spacing: 0.03em;
    }
}

@media screen and (max-width: 375px) {
    .duration{
        /* flex-basis: 45%; */
       
    }
}

@media screen and (max-width: 320px) {
    .duration{
        /* flex-basis: 134px; */
       
    }
}