.connect__container{
display: flex;
flex-wrap: wrap;
height: 80%;
justify-content: center;
align-items: center;

}

.connect{
border: 1px solid black;
width: 270px;
height: 270px;
left: 568px;
top: 126px;
max-width: 304px;
background: #FFFFFF;
border: 1px solid #E5E8F0;
box-sizing: border-box;
border-radius: 6px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: inter !important;
font-size: 14px !important;
font-weight: 400 !important;
margin: 10px;
padding: 10px 30px;
}

.fox{
    margin-bottom: 10px;
    /* max-width: 200px; */
}

.fox img{
    width: 100%;
    display: block;
    margin: 0 auto;
}

.meta{
    padding-top: 10px;
    margin-bottom: 23px;
    max-width: 110px;
}

.meta img{
    width: 100%;
    display: block;
}

.connect__button{
padding: 10px 16px;
color: white;
background: #3180F6;
border-radius: 6px;
cursor: pointer;
}

.connect__button a{
    color: #fff;
}

.required{
    color: #f63131;
    font-size: 12px;
}


@media screen and (max-width: 600px) {

    .connect__container{
        align-content: flex-start;
    }
}

.con__box{
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 10px;
    padding: 10px 30px;
    background: #FFFFFF;
    border: 1px solid #E5E8F0;
    border-radius: 6px;
}

.con__btn{
    padding: 10px 16px;
    color: white;
    background: #3180F6;
    border-radius: 6px;
    cursor: pointer;
}

.con__btn a{
    color: white !important;
}

.con__header{
    height: 120px;
    width: 110px;
}

.con__header img{
    width: 100%;
}