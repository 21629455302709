.no-items__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 26px;
}

.no-items-result{
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    color: #040D27;
}

.no-items-msg{
    font-size: 24px;
    font-weight: 400;
    line-height: 30.24px;
    color: #040D27;
}

.no-items-btn{
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    background-color: #3D63F3;
    width: 205px;
    height: 44px;
    border-radius: 6px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}