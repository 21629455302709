.token{
    width: calc(100%/4);
    opacity: 0.7;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .17s;
    overflow: hidden;
}

.token--disabled{
    width: calc(100%/4);
    opacity: 0.7;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
    pointer-events: none;
    overflow: hidden;
}

.token:hover{
    opacity: 1;
}

.token--active{
    opacity: 1;
    width: calc(100%/4);
    opacity: 1;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: none;
    overflow: hidden;
}

.token--disabled img{
    width: 100%;
    border-radius: 6px;
}

.token img{
    width: 100%;
    border-radius: 6px;
}

.token--active img{
    width: 100%;
    border-radius: 6px;
}

@media screen and (max-width: 600px) {
    .token{
        width: 50px;
        height: 50px;
    }
    .token--active{
        width: 50px;
        height: 50px; 
    }
}


@media screen and (max-width: 425px){
    .token{
        width: 50px;
        height: 50px;
    }
    .token--active{
        width: 50px;
        height: 50px; 
    }

}