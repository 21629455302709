input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.notify-modal{
    position: absolute;
    top: 25%;
    left: 40%;
    right: auto;
    bottom: auto;
    width: 375px;
    /* height: 430px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 32px 10px;
    outline: none;
}

.notify-modal-close{
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
}

.sent-msg__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24PX;
}

.notify-form__title, .sent-msg__title{
    padding-bottom: 32px;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.02em;
}

.sent-msg__title{
    text-align: center;
    padding: 0;
}

.sent-msg__text{
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
}

.sent-msg__button{
    background: #3D63F3;
    color: white;
    height: 32px;width: 56px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sent-msg__button--unclickable{
    background: #ECEDF3;
    color: #1D1D1D;
    height: 32px;width: 56px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.notify-modal form{
    width: calc(100% - 15px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.notify-input{
    width: calc(100% - 30px);
    margin: 0 auto;
    border-radius: 6px;
    border: 1px solid #BDBECB;
    padding: 10px;
    position: relative;
}

.notify-submit{
    margin-top: 10px;
    background-color: #3D63F3;
    border: none;
    color: white;
    padding: 6px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.notify-submit--unclickable{
    margin-top: 10px;
    background: #ECEDF3;
    color: #1d1d1d73;
    border: none;
    padding: 6px 16px;
    border-radius: 6px;
    cursor: pointer;
    pointer-events: none;
}

.notify-input input{
    border: unset;
}

.notify-input input::selected{
    background: unset;
}

.notify-input input:focus{
    outline: none;
}

.notify-submit{
    width: 90px;
}

.invalid{
    color: #C92454;
    position: absolute;
    bottom: -16px;
    left: 0;
    font-size: 12px;
    background-color: #FEE5EC;
    padding: 0 4px;
    border-radius: 2px;
}

@media screen and (max-width: 768px) {
    .notify-modal{
        width: 405px;
        top: 25%;
        left: 25%;
    }
}

@media screen and (max-width: 425px) {
    .notify-modal{
        width: 385px;
        top: 25%;
        left: 10px;
    }
}


@media screen and (max-width: 375px) {
    .notify-modal{
        width: 335px;
        top: 25%;
        left: 10px;
    }
}

@media screen and (max-width: 320px) {
    .notify-modal{
        width: 280px;
        top: 25%;
        left: 10px;
    }
}