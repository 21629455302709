.stake__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
}

.stake{
    max-width: 622px;
    height: auto;
    padding: 30px 0;
    background: #FFFFFF;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
    color: #040D27;
    border: 1px solid #E5E8F0;
}

.summary{
    max-width: 486px;
    height: auto;
    padding: 30px 0;
    background: #FFFFFF;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #E5E8F0;
}

.stake__title{
    padding-left: 30px;
    padding-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 126%;
}

.line{
    height: 0;
    border-top: 1px solid #E5E8F0;
    margin-bottom: 11px;
}

@media screen and (max-width: 1140px) {
    .summary{
        flex-basis: 620px;
        max-width: 620px;
    }
}

@media screen and (max-width: 600px) {
    .stake__container{
        display: flex;
        flex-wrap: wrap;
        max-width: 600px;
    }
    .stake{
        flex-basis: 600px;
        border-radius: unset;
        height: auto;
        padding-bottom: 10px;
        margin-bottom: 0;
        border-bottom: 1px solid #E5E8F0;
        padding-top: 14px;
    }
    .summary{
        flex-basis: 600px;
        max-width: 600px;
        border-radius: unset;
    }
    .stake__container{
        gap: 0;
    }
}

@media screen and (max-width: 425px){

    .stake__title{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 126%;
    }


}
