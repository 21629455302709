.account__box{
    max-width: 175px;
    height: 32px;
    display: flex;
    align-items: center;
    border: 1px solid #D9DFED;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    padding: 10px;
    background: #EDF0F8;

}

.account__box:hover{
    cursor: pointer;
}

.account__container{
    display: flex;
    justify-content: flex-end;
}

.account__icon{
    margin-right: 10px;
}

.account__icon canvas {
    border-radius: 25px;
    margin-right: -4px;
    width: 18px;
    height: 18px;
}

@media screen and (max-width: 320px) {
    .account__box{
        top: 10px;
        right: 10px;
    }
}

@media screen and (max-width: 375px) {
    .account__box{
        top: 15px;
        right: 10px;
    }
}

@media screen and (max-width: 425px) {
    .account__box{
        top: 15px;
        right: 10px;
    }
}