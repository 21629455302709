.staking-reached-container{
    padding-top: 15px;
    width: 426px;
    height: 230px;
    background-color: #F2F4F8;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.staking-reached__title{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.03em;
}

.staking-reached__text{
    padding: 0 71.5px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.03em;
    padding-bottom: 24px;
}

.staking-reached__button{
    background: #3D63F3;
    width: 110px;
    height: 34px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
}

@media screen and (max-width: 1140px) {
    .staking-reached-container{
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .staking-reached__text{
        padding: 10px 20px 20px 20px;

    }
}