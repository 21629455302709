
.agreement{
    display: flex;
    padding: 16px;
    margin: 56px 0 16px 0;
    gap: 10px;
    background: #F4F7FF;
    border-radius: 6px;
    font-size: 16px;
    line-height: 126%;
    display: flex;
    align-items: flex-start;
    letter-spacing: 0.03em;
    color: #686E7D;
}

.agreement__text{
font-size: 16px;
}

.agreement__text a{
    color: #2853EB;
    /* cursor: pointer; */
}

.agreement__text a:hover{
    cursor: pointer;
}

@media screen and (max-width: 600px){
    .agreement{
        margin-top: 10px;
    }
}

@media screen and (max-width: 425px){
    .agreement{
        margin-top: 25px;
    }
}
