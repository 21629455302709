.app__wraper{
  max-width: 1140px;
  height: 100vh;
  margin: 0 auto;
}

@media screen and (max-width: 600px){
  .app__wraper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .gallery__container {
    height: calc(100vh - 400px) !important;
  }
}

/* @media  screen and (max-width: 359px) {
  .modal-button{
    line-height: 200%;
    padding-bottom: -30px;
    overflow: hidden;
  }
}

@media  screen and (max-width: 328px) {
  .modal-button{
    padding-top: -20px;
    line-height: 200%;
    padding-bottom: -30px;
    overflow: hidden;
  }
} */