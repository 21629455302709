.progress-bar{
    display: flex;
    flex-direction: column;

    margin:40px 0;
}

.progress__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.progress__title{
    font-weight: 500;
    font-size: 16px;
    line-height: 126%;
    

    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
}

.progress__remaining{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 126%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.03em;

    color: #686E7D;
}

.bar{
    height: 9px;
    background: #ECEDF3;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.bar__prog{
    position: absolute;
    background: rgb(230,234,245);
    background: linear-gradient(90deg, rgba(230,234,245,1) 0%, rgba(49,128,247,1) 100%);
    height: 9px;
    left: 0px;
}