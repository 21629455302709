
.navbar--disconnected{
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery__connect{
    height: 40px;
    background: #EDF0F8;
    border: 1px solid #D9DFED;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 16px;
}

.gallery__connect:hover{
    cursor: pointer;
}

.navbar--mobile{
    display: none;
}

.xp__logo{
    padding-top: 26px;
    padding-bottom: 18px;
    margin-right: 10px;
    cursor: pointer;
}

.xp__logo img{
    width: 100%;
}


/* @media screen and (max-width: 1140px){
    .navbar{
        max-width: 600px;
        margin: 0 auto;
    }
    .navbar--disconnected{
        max-width: 600px;
        margin: 0 auto;
    }
} */

/* @media screen and (max-width: 600px) {
    .navbar{
        display: none;
    }

    .navbar--disconnected{
        max-width: calc(100% - 20px);
    }
    .navbar--mobile{
        max-width: calc(100% - 20px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;
        padding: 10px;
    }

    .xp__logo--mobile{
        padding: 10px 0;
        width: 24vw;
    }

    .xp__logo--mobile img{
        width: 100%;
    }

} */
/* 
@media screen and (max-width: 1140px){
 .xp__logo{
     width: 130px;
 }   
}


@media screen and (max-width: 425px){
    .navbar--mobile{
        padding: 10px;
        justify-content: space-between;
        gap: 0;
        padding: 0 0 10px 0;
    }
    .xp__logo--mobile{
        width: 200px;
        margin: unset;
    }
}
 */

.navbar{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 25px 0;
    gap: 20px;
    font-size: 16px;
    line-height: 20.16px;
    letter-spacing: 3%;
}

/* @media screen and (max-width: 1155px) {
    .navbar{
        max-width: ;
    }
}

@media screen and (max-width: 1140px) {
    .navbar{
        gap: 10px;
    }
} */

.navbar__nav-buttons{
    flex-grow: 4;
}

.account__container{
    flex-grow: 1;
}

.nav-buttons__container{
    width: 252px;
    height: 40px;
    display: flex;
    border-radius: 8px;
}

.navbar__btn{
    padding: 10px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    color: #040D27;
    opacity: 0.6;
}

.navbar__btn--active{
    padding: 10px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    color: #2853EB;
    background: #FFFFFF;
    border-radius: 6px;
}

.navbar__btn--disabled{
    padding: 10px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    color: #1D1D1D;
    opacity: 0.2;
    border-radius: 6px;
}

.nav__buttons--mob{
    padding: 0 8px 20px 8px;
    margin: 0 auto;
}

.mob__buttons__wrapper{
    display: flex;
    background: #ECEDF3;
    border-radius: 8px;
    padding: 4px;
}

.navbar__btn:hover{
    cursor: pointer;
}

.nav__mob{
    max-width: 625px;
    margin: 0 auto;
}

.nav__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 8px;
    margin: 0 auto;
}

.top__logo{
    margin-right: 10px;
}

.nav__top img{
    max-width: 100%;
}

@media screen and (max-width: 1140px) {
    .navbar{
        max-width: 600px;
        gap: 10px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 625px) {
 
    .nav__mob{
        width: 100%;
    }
    
    .navbar{
        display: none;
    }
}

@media screen and (min-width: 625px) {
    .nav__mob{
        display: none;
    }
}