.nft__widget{
    width: 426px;
    height: 314px;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 30px;
}

.widget__art{
    width: 292px;
    height: 292px;
    /* border: 1px solid black; */
    overflow: hidden;
    display: flex;
 
} 

.art-row{
    display: flex;
    transition: ease-in-out 0.35s;
}

.widget__art img{
    width: 292px;
}

.arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 50%;
}

.arrow--disabled{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border: 1px solid #E5E8F0;
    box-sizing: border-box;
    border-radius: 50%;
    opacity: 0.3;
    pointer-events: none;
}

.arrow:hover{
    cursor: pointer;
    background-color: #E5E8F0;
}

@media screen and (max-width: 600px) {
    .nft__widget{
        margin: 0 auto;
    }
}

@media screen and (max-width: 425px) {
    .nft__widget{
        margin: 0 auto;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
    }
    .arrow{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}

@media screen and (max-width: 320px) {
    .nft__widget{
        margin: 0 auto;
        display: flex;
        width: 290px;
        align-items: center;
        justify-content: space-between;
    }
    .arrow{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
}