
.Modal{
    position: absolute;
    width: 400px;
    height: auto;
    padding: 20px 10px 20px 10px;
    border-radius: 6px;
    box-shadow: 0px 0px 13px 0px #00000050;
    font-family: inter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
  }
  
  
  .modal-header{
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E8F0;
    position: relative;
  }
  
  .modal-title{
    font-weight: 500;
    font-size: 18px;
    line-height: 126%;
    display: flex;
    width: 100%;
    padding: 10px;
    align-items: center;
    justify-content: center;
  
  }
  
  .modal-close{
    position: absolute;
    right: 10px;
  }
  
  .modal-close:hover{
    cursor: pointer;
  }
  
  .modal-line{
    width: 100%;
    border-top: 1px solid black;
  }
  
  
  .modal-body{
    padding: 10px;
    flex-basis: 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .modal-icon{
    margin: 10px;
  }
  
  .modal-subtitle{
    font-weight: 600;
    text-align: center;
  }
  
  .modal-msg{
    font-weight: 400;
    text-align: center;
  }

  .modal__buttons{
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .modal-button{
    color: #fff;
    background: #3180F7;
    border-radius: 6px;
    margin-top: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: 0.17s;
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }
  
  .modal-button-close{
    color: rgb(143, 143, 143);
    background: #ecebeb;
    border-radius: 6px;
    margin-top: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: 0.17s;
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-button-close:hover{
    cursor: pointer;
    color: #2549D1;
  }
  
  .modal-button:hover{
    cursor: pointer;
    background-color: #2549D1;
  }
  
  @media screen and (max-width: 600px){
    .Modal{
      width: calc(100% - 100px);
    }
  }
  
    
  @media screen and (max-width: 375px){
    .modal-button-close, .modal-button{
     
      width: 60%;

    }

  }