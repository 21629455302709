
.button{
    max-width: 426px;
    height: 44px;
    color: #fff;
    background: #3180F7;
    border-radius: 6px;
    margin-top: 6px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: 0.17s;
}

.button:hover{
    background-color: #2549D1;
}

.loading{
    pointer-events: none;
}

.approved{
    max-width: 426px;
    height: 44px;
    background-color: unset;
    border-radius: 6px;
    margin: 6px 0;
    color: #02C68B;
}
.approved img{
    margin-right: 5px;
}

.lock{
    max-width: 100%;
    height: 44px;
    background-color: #ECEDF3;
    border-radius: 6px;
    margin-top: 6px;
}



.lock span{
    margin-left: 2px;
    padding-bottom: 2px;
}

.button span{
    margin-left: 2px;
    padding-bottom: 2px;
}

.summary__button{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1140px){
    .button, .approved{
        max-width: 100%;
    }

    
}

@media screen and (max-width: 600px) {
    .button, .lock{
        max-width: unset;
    }
}

