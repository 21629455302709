.agree-modal{
    position: absolute;
    top: 100px;
    left: 200px;
    right: 200px;
    bottom: 100px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    overflow: hidden scroll;
    outline: none;
}

.agree-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.603);
}

.agreement-modal__header{
    display: flex;
    flex-direction: column;
}

.agreement-modal__title{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 126%;
    text-align: center;
}

.terms__list__item{
    margin-bottom: 20px;
}

.agreement-modal__header .bold{
    font-weight: bold;
}


.list__item__tittle{
    font-weight: 600;
    margin-bottom: 10px;
}

.list__item__list{
    margin-bottom: 30px;
}


@media screen and (max-width: 425px) {
    .agree-modal{
        position: absolute;
        top: 60px;
        left: 40px;
        right: 40px;
        bottom: 60px;
        background-color: rgb(255, 255, 255);
        font-size: 14px;
    }

    .agree-overlay{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .agreement-modal__container{
        width: 100%;
    }

    .agreement-modal__container ol {
        padding: 15px;
    }

    .list__item__list{
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .agree-modal{
        position: absolute;
        top: 60px;
        left: 40px;
        right: 40px;
        bottom: 60px;
        background-color: rgb(255, 255, 255);
    }

    .agree-overlay{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .agreement-modal__container{
        width: 100%;
    }

    .agreement-modal__container ol {
        padding: 15px;
    }

    .list__item__list{
        margin-bottom: 10px;
    }
}